import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_givingReasonDesire.jpg";
import BackOption from "../../../BackOption";
import TrackContainer from "../../TrackContainer";
import MainMenuOption from "../../../footer/MainMenuOption";

enum tracksGivingReasonDesire {
    track01 = "Thy Laws, Thy Grace",
    track02 = "Nach der Sehnsucht",
    track03 = "Six Tongues",
    track04 = "Et vandrendre tre",
    track05 = "An den Wassern",
    track06 = "Only Words Remain",
    track07 = "Auf weißem Grund",
    track08 = "Babels Töchter",
    track09 = "Lang verweht",
    track10 = "Nur kurze Rast",
    track11 = "Und alles kam",
    track12 = "Where Is Your Saviour?"
}

const GivingReasonDesireDetails = () => {
    const { t } = useTranslation();
    let opusName = 'givingReasonDesire';

    function generateLyrics(track: string) {
        var text
        switch(track) {
            case tracksGivingReasonDesire.track01: {
                text = <p>
                    LORD, I'd spread my sore distress<br/>
                    and guilt before Thine eyes<br/>
                    against Thy laws, against Thy grace<br/>
                    how high my crimes arise<br/>
                    should'st Thou condemn my soul to hell<br/>
                    and crush my flesh to dust<br/>
                    heaven'd approve Thy vengeance well<br/>
                    and earth must own it just.<br/> <br/>

                    I from the stock of Adam came<br/>
                    unholy and unclean<br/>
                    all my original is shame<br/>
                    and all my nature sin<br/>
                    born in a world of guilt, I drew<br/>
                    contagion with my breath<br/>
                    and as my days advanced, I grew<br/>
                    a juster prey for death.<br/> <br/>

                    Cleanse me, O LORD, and cheer my soul<br/>
                    with Thy forgiving love<br/>
                    O make my broken spirit whole<br/>
                    and bid my pains remove<br/>
                    let not Thy spirit quite depart<br/>
                    nor drive me from Thy face<br/>
                    create anew my vicious heart<br/>
                    and fill it with Thy grace.
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track02: {
                text = <p>
                    Ich werf' mich in die schwarzen Fluten eines Ozeans von Lust<br/>
                    viel zu lang stand ich am Ufer, fühlte dort in meiner Brust<br/>
                    dieses Sehnen nach der Weite, ein Verlangen Tag für Tag<br/>
                    dem ich so lang widerstand, doch mich nun nicht mehr beugen mag.<br/> <br/>

                    Ich werf' mich in die schwarzen Fluten eines Ozeans von Gier<br/>
                    viel zu lang stand ich am Ufer, fühlte tobend dort in mir<br/>
                    das Brodeln eines heißen Kosmos, der siedend dort ein Herze nährt<br/>
                    wütend, gärend, wild pulsierend den Körper wüst durchfährt.<br/> <br/>

                    Ich stürz' mich in die schwarzen Fluten eines Ozeans von Wut<br/>
                    vor mir nichts als kühle Tiefe, hinter mir ein Meer von Glut<br/>
                    über mir schließt sich das Wogen, frei, gelöst sink ich hinab<br/>
                    in des Brausens stilles Toben, lasse alle Schranken droben.<br/> <br/>

                    Ich stürz' mich in die schwarzen Fluten eines Ozeans im Traum<br/>
                    laß' die Brandung mich bedecken, gurgelnd bettet mich der Schaum<br/>
                    lächelnd nimmt mich auf die Strömung, an der ausgestreckten Hand<br/>
                    ich fliehe in die sanften Arme der Geliebten, die mich fand.
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track03: {
                text = <p>
                    Six tongues' calls charming me, some sweet and gentle promise of<br/>
                    safety in a thorny world I appealed for deeply<br/>
                    sweet-talking melodies, murmured, wistful lullabies<br/>
                    kindly, softly they caress me unbeknownst and bland in wise.<br/> <br/>

                    Six souls keep reaching me, six sirens, a deceiving breed<br/>
                    six streetlights in the foggy morn', just blazing since the gloom has gone<br/>
                    six lighthouse spots that I approached with bare feet in the baneful dew<br/>
                    their cone of light depicts I lost my trace and road and route.<br/> <br/>

                    They blew away the blindfold's band, so I can see<br/>
                    in solitude I stand, blind shadows' lies blandishing me<br/>
                    six tongues' calls take my breath, disordered I am left<br/>
                    they show me the emptiness of what I though I'd love.<br/> <br/>

                    Six greyish shapes evading my view, a sighing fragile voice<br/>
                    trying to catch my ear through a life's collapse's noise<br/>
                    six tongues' calls take my breath, rocks being seated on my breast<br/>
                    the bracing pond of Tantalus was a likely pleasant choice.<br/> <br/>

                    Six tongues' calls echoing through this silent town<br/>
                    obviously, they have no idea who them once has grown<br/>
                    six silky echoes sank in the sea<br/>
                    that washed away my vision's shrouds and now is drowning me.
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track04: {
                text = <p>
                    Jeg er et vandrende tre med røttene løse.<br/>
                    Et tre av nerver der skjelver,<br/>
                    med grene og kvister der stadig skyter<br/>
                    og blade og skudd som veier og skjelver mot nye lidelser.<br/>
                    Hva er det for skudd som skyder ut på mitt sinds tre?<br/>
                    Kunne jeg hindre de villskudds vekst<br/>
                    ja, om jeg nare kunne hindre de villskuddsvekst<br/>
                    så jeg rolig fikk gro, stille gro. Mot himmelen.<br/>
                    Men villskudd skyder på mitt livstre, skudd som tar saften,<br/>
                    suger marven fra de kviste og grene<br/>
                    som jeg ser ville stå lenge --- om<br/>
                    ikke deres næring ble dem berøvet av de andre.<br/> <br/>

                    Det ble figurer, linjet, slanke, magre<br/>
                    just som meg selv dengang, så benet, senet,<br/>
                    en stramhet som jeg skapte i ekstase,<br/>
                    mens tanken kom for sent til min idé.<br/>
                    Den tid står for meg som jeg hørlig åndet<br/>
                    mer ut enn inn og med en hissig stønnen.<br/>
                    Nu kan blot storme fylle mine lunger ---<br/>
                    som om jeg ånder mere inn enn ut.<br/>
                    Da livet mitt arbeider mot balanse,<br/>
                    og jeg kun ånderhalvt i mine verker,<br/>
                    så ønsker jeg at hjertet ville kreve<br/>
                    at begge åndredag fikk like makt.<br/> <br/>

                    ---<br /> <br />

                    O night, my refuge, hear my cries<br/>
                    behold my flowing tears<br/>
                    for earth and hell my hurt devise<br/>
                    and triumph in my fears<br/>
                    their rage is levelled at my life<br/>
                    my soul with guilt they load<br/>
                    and fill my thoughts with inward strife<br/>
                    to shake my only hope.<br/> <br/>

                    With inward pain my heart-strings sound<br/>
                    I groan with every breath<br/>
                    horror and fear beset me round amongst the shade of death<br/>
                    O were I like a feathered dove<br/>
                    and innocence had wings<br/>
                    I'd fly and make a long remove<br/>
                    from all these restless things.<br/> <br/>

                    Let me to some wild desert go<br/>
                    and find a peaceful home<br/>
                    where storms of malice never blow<br/>
                    temptations never come<br/>
                    vain hopes and vain inventions all<br/>
                    to escape the rage of hell<br/>
                    the empty skies I call<br/>
                    remain silent to my yell.
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track05: {
                text = <p>
                    An den Wassern, an den Straßen<br/>
                    einer Neuen, Unbekannten<br/>
                    wo manche schon ihr Leid vergaßen<br/>
                    manche schon darin ertranken<br/>
                    auf den Pflastern, die sie säumen<br/>
                    wo borstige Gestalten wanken<br/>
                    in Scherben sich ein Leben träumen<br/>
                    dem sie ferner niemals standen.<br/> <br/>

                    Im Geflicht der kleinen Gassen<br/>
                    die sich durch die Häuser ranken<br/>
                    die, von Hoffnungen verlassen<br/>
                    längst im Winde sich entwanden<br/>
                    wo der Träumer sich im blinden<br/>
                    Licht der Nacht als Narr erkannte<br/>
                    Zähren still im Staub zerrinnen<br/>
                    verblaßt einsam ein Gedanke.<br/> <br/>

                    Die Ströme wühlen, mahlen still unter regungslosen Spiegeln<br/>
                    ein Versprechen unter Siegeln, deren Wachs noch nicht gekühlt<br/>
                    die Winde flechten flink ihr Netz mit leichter Hand und leis' darüber<br/>
                    es webt die Parze blind ihr Band im Abendlicht, lauscht nach den Liedern<br/>
                    die ihr künden von dem Morgen, noch kein Menschenohr vernommen<br/>
                    was gelten ihr Dein Sehnen, Sorgen – das Siegel zittert, ist zerronnen . . .
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track06: {
                text = <p>
                    I've seen the edge of the world. I've seen the oceans pour yonder<br/>
                    I've seen the birds rise in the skies into the rolling thunder<br/>
                    I've slept under the blood moon. Its rays poured down on you<br/>
                    gazed at the stars, crazed, torn apart, till black faded to blue.<br/>
                    I saw the storm coming and going, carrying its prey away<br/>
                    I've seen the plague spreading and growing, yet I stand here today.<br/> <br/>

                    There is nothing new under the sun – nothing has changed or will<br/>
                    tomorrow is just a future past, water under the mill.<br/> <br/>

                    I saw myself in the gutter in moments of weakness and fear<br/>
                    I saw the world burn at every turn, yet my feet carried me here<br/>
                    I've seen the world under arms. I've seen the crazy rise<br/>
                    I've lived my own lies, laughed at the wise, succumbed to vanity's charm<br/>
                    I've seen the good ones suffer. I've seen the worthless succeed<br/>
                    I have been blind, kept looking behind, chiming their hollow creed.<br/> <br/>

                    There is nothing new under the sun – nothing has changed or will<br/>
                    tomorrow is just a future past, water under the mill<br/>
                    There is nothing new under the sun – nothing but sorrow and fear<br/>
                    nothing has changed, it's all the same, the gods observe and cheer<br/>
                    there is nothing new under the sun – nothing has changed or will<br/>
                    all will remain in delusion and shame, only words remain.
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track07: {
                text = <p>
                    So schwer mein Herz, so trüb die Zeit, und nie Genügen<br/>
                    es zieht mich in den Strudel weit Wehmut, Schmerz und Vergnügen<br/>
                    ich kann den Himmel kaum mehr seh'n, den maienblauen<br/>
                    so überstürmen wilde Weh'n mich jetzt mit Lust und Grauen.<br/> <br/>

                    Und wer mir auch sein Herz geschenkt – wohin die Lieben?<br/>
                    Und wer mit Wasser mich getränkt – wo sind sie alle blieben?<br/>
                    Und jeder helle Sonnenblick, der mich getroffen?<br/>
                    Wer nahm den letzten Rest von Glück, mein Träumen und mein Hoffen?<br/> <br/>

                    Mein zuckend' Herz, ich warf es hin zu rasten<br/>
                    und wälzte drüber Lust, Gewinn, Schmerz, Wissen, Bergeslasten<br/>
                    ob es sich quält und drückt und engt – in wilden Stunden<br/>
                    da schleudert's flammend und versengt empor, was es gebunden.<br/> <br/>

                    Und schrieb ich drüber schwarz und dick den Blättern<br/>
                    blieb wenig doch die Schrift zurück in blutigroten Lettern<br/>
                    die Schrift, die auf dem weißen Grund ein Gott gezogen<br/>
                    der Gott war ich und dieser Grund hat sich und mich belogen.<br/> <br/>

                    O Ruhe, Fremdling meiner Zeit, ich grüße<br/>
                    Dich aus der stummen Einsamkeit, wo ich mein Leben büße<br/>
                    aus meines Lebens Bronnen quill in heil'gen Fluten<br/>
                    ich schau auf Dich und lasse still mein sehnend' Herz verbluten.
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track08: {
                text = <p>
                    Babels Töchter singen wieder, ihre Stimmen hellen die Nacht<br/>
                    fahren Dir durch Herz und Glieder, kosen Deine Sinne sacht<br/>
                    ihre sanften Sänge hallen durch die dunkle, schwüle Stadt<br/>
                    ein tauber Alter schaufelt lächelnd ihren Söhnen heut ein Grab.<br/> <br/>

                    Zu den Wolken, zu den Sternen, zu den Fernen, die sie stumm<br/>
                    durchqueren blickt er gerne grübelnd, träumend Stund' um Stund'<br/>
                    möchte ihrem Zuge folgen, endlos auf der stillen Bahn<br/>
                    jenseits Edens stetig vollem Horn von Eitelkeit und Wahn.<br/> <br/>

                    Endlos durch den stillen Raum, der noch nicht eine Klage hörte<br/>
                    den keine Stimme jemals störte, vorwärts – ohne Lieb' und Heim<br/>
                    zieht der Narr in diesem Traume als einer dieser toten Steine<br/>
                    die ihm hier so lockend scheinen, nur vorwärts, vorwärts – stumm, allein.<br/> <br/>

                    Babels Töchter singen wieder, ihre Stimmen hellen die Nacht<br/>
                    fahren Dir durch Herz und Glieder, kosen Deine Sinne sacht<br/>
                    ihre sanften Sänge hallen durch die dunkle, schwüle Stadt<br/>
                    der taube Alte singt ermüdet auf seine Knie herab.
                    </p>
                break;
            }
            case tracksGivingReasonDesire.track09: {
                text = <p>
                    Hier, wo alter Traum begann<br/>
                    verschwimmt im Dunst sein letztes Zeichen<br/>
                    die Wurzel seines Niedergangs<br/>
                    er ist entrückt, doch mag nicht weichen.<br/> <br/>

                    Hier, wo alter Traum verklang<br/>
                    läßt doch die Welt ihn nicht verhallen<br/>
                    wie er einst den Tag durchdrang<br/>
                    erfüllt ihn nun sein sanftes Lallen.<br/> <br/>

                    Wie Wind, der Rauch schon lang verweht'<br/>
                    noch eine Ahnung hinterläßt<br/>
                    bleibt doch die Luft, die Ahnung trägt<br/>
                    von der Flammen rauschend' Fest.<br/> <br/>

                    An diesem Ort wird Ahnung bleiben<br/>
                    sie ist ihm schließlich tief vermählt<br/>
                    doch gibt es keinen, der versteht<br/>
                    was sie verborgen in sich trägt.
                </p>
                break;
            }
            case tracksGivingReasonDesire.track10: {
                text = <p>
                    Vor meinen Blicken dehnt sich weit<br/>
                    die Gegend aus – einförmig, eben<br/>
                    nicht Baum nicht Hütte weit und breit<br/>
                    will Ruhestatt, will Heimat geben.<br/> <br/>

                    Zu trüb zu seh'n, um zu versteh'n, zu müd zum weitergehen<br/>
                    laß nicht los, doch löse Dich vom Sog des Bernsteinregens<br/>
                    ist auch alles, was Du tust, vermeintlich nur vergebens<br/>
                    fehlt Dir auch Zuversicht und Mut, sei darum nicht verlegen.<br/> <br/>

                    Müd wird das Auge, matt die Kraft<br/>
                    zuviel der Sorg, der Müh'n zu viele<br/>
                    so immer auf der Wanderschaft<br/>
                    und immer gleich entfernt vom Ziele.<br/> <br/>

                    Zu trüb zu seh'n, um zu versteh'n, zu müd zum weitergehen<br/>
                    laß nicht los, doch löse Dich vom Sog des Bernsteinregens<br/>
                    ist auch alles, was Du tust, vermeintlich nur vergebens<br/>
                    fehlt Dir auch Zuversicht und Mut, sei darum nicht verlegen.<br/> <br/>

                    Dazu noch des Erinnerns Last<br/>
                    so soll die Eb'ne ich durchmessen<br/>
                    o für mein Herz nur kurze Rast<br/>
                    nur kurze Rast, um zu vergessen . . .
                </p>
                break;
            }
            case tracksGivingReasonDesire.track11: {
                text = <p>
                    Wenn nach erlosch'nen Gluten auch die Farbe<br/>
                    der Erde wechselt, sich mit Staub belegend<br/>
                    und trägt auch jedes in getrennte Gegend<br/>
                    seine Schwermut und gesteht: ich darbe.<br/> <br/>

                    Und wird der inn're Ruf zu Dir auch leiser<br/>
                    ich fühle stets: ich muß mich nach Dir neigen<br/>
                    Dein ist mein Tag zuerst, ich bin Dir eigen<br/>
                    und um uns steh'n vom Frühling her die Reiser.<br/> <br/>

                    Wohl kommt ein and'rer Duft aus weichem Flachse<br/>
                    des Grases und aus silbrig-welkem Blatte<br/>
                    Erinnerung an Fluß und Fels und Matte<br/>
                    weckt nur den Wunsch für Dich: sei froh und wachse!<br/> <br/>

                    Und lockt es dumpf, daß ich nach dem Zerknittern<br/>
                    der falben Reste bald an fremder Stätte<br/>
                    die Freiheit oder neue Freuden hätte<br/>
                    so dringt wie zum verwandten Blut ein Zittern.<br/> <br/>

                    So denk ich dieses nun schon langen Stückes<br/>
                    vereinter Fahrt und dieser starken Schlingen<br/>
                    die uns unlöslich insgeheim umfingen<br/>
                    meiner früher'n Qual und Deines Glückes.<br/> <br/>

                    Nun laß mich rufen über die verschneihten<br/>
                    Gefilde wo Du wegzusinken drohst<br/>
                    wie Du mich unbewußt durch die Gezeiten<br/>
                    gelenkt – im Anfang Spiel und dann mein Trost.<br/> <br/>

                    Du kamst beim Prunk des blumigen Geschmeides<br/>
                    ich sah Dich wieder bei der ersten Mahd<br/>
                    und unter'm Rauschen rötlichen Getreides<br/>
                    wand immer sich zu Deinem Haus mein Pfad.<br/> <br/>

                    Dein Wort erklang mir bei des Laubes Dorren<br/>
                    so traulich, daß ich ganz mich Dir befahl<br/>
                    und als Du  schiedest, lispelte verworren<br/>
                    in Seufzertönen das verwaiste Tal.<br/> <br/>

                    So hat das Schimmern eines Augenpaares<br/>
                    als Ziel bei jeder Wanderung geglimmt<br/>
                    so ward Dein sanfter Sang der Sang des Jahres<br/>
                    und alles kam, weil Du es so bestimmt.
                </p>
                break;
            }
            case tracksGivingReasonDesire.track12: {
                text = <p>
                    Where is your saviour? Where is your Lord?<br/>
                    Where's all he gave ya? Where is his court?<br/>
                    None of him left here, it seems to me<br/>
                    all devastated you're left to be.<br/> <br/>

                    Where is your saviour? Where is your Lord?<br/>
                    Where's all he gave ya? Where is his court?<br/>
                    His humble slave, ya cannot deny<br/>
                    his promised kingdom appears a lie.<br/> <br/>

                    Where is your saviour? Where is your Lord?<br/>
                    Where's all he gave ya? Where is his court?<br/>
                    Where has your faith gone? What has remained?<br/>
                    So much intention – so little gained.<br/> <br/>

                    Where is your saviour? Where is your Lord?<br/>
                    Where's all he gave ya? Where is his court?<br/>
                    The rising mourning, as chiming bells<br/>
                    cheers your sentence for living hell.
                </p>
                break;
            }
            default: {
                text = "Unsuccessfully tried to fetch lyrics for song " + track + "..."
                break;
            }
        }
        return (
            <p id={`${opusName}${track}Lyrics`} className='centerText'>
                {text}
            </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>{t(`${opusName}_description`)}</p>
                <p>
                    {t(`${opusName}_details01a`)}
                    <a href={`https://moineaufolk.bandcamp.com/releases`}>
                        Bandcamp
                    </a>
                    {t(`${opusName}_details01b`)}
                    <a href={`https://open.spotify.com/album/05G1S3iihzbwBppuw4weiv?si=34usH3cvQpuMH1YEcfNpdA`}>
                        Spotify
                    </a>
                    {t(`${opusName}_details01c`)}
                </p>
                <p>{t(`${opusName}_details01d`)} </p>
            </div>

            <Link className={"border marginBottom bodyBtn"} to={{pathname: '/img/galleryGivingReasonDesire'}}><MainMenuOption label={t('galleryGivingReasonDesire')}/></Link>

            <div className={`marginPicture`}>
                <TrackContainer header={tracksGivingReasonDesire.track01} credits={`feat. John van der Lieth & David E. Williams. Words: Isaac Watts`} lyrics={generateLyrics(tracksGivingReasonDesire.track01)} />
                <TrackContainer header={tracksGivingReasonDesire.track02} credits={`Words: Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track02)} />
                <TrackContainer header={tracksGivingReasonDesire.track03} credits={`feat. John van der Lieth. Words: Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track03)} />
                <TrackContainer header={tracksGivingReasonDesire.track04} credits={`feat. Didrik Søderlind & Peter Feldl. Words: Gustav Vigeland, Isaac Watts & Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track04)} />
                <TrackContainer header={tracksGivingReasonDesire.track05} credits={`Words: Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track05)} />
                <TrackContainer header={tracksGivingReasonDesire.track06} credits={`Words: Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track06)} />
                <TrackContainer header={tracksGivingReasonDesire.track07} credits={`feat. Nicolas van Meirhaeghe. Words: Friedrich Nietzsche`} lyrics={generateLyrics(tracksGivingReasonDesire.track07)} />
                <TrackContainer header={tracksGivingReasonDesire.track08} credits={`feat. Miro Snejdr. Words: Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track08)} />
                <TrackContainer header={tracksGivingReasonDesire.track09} credits={`Words: Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track09)} />
                <TrackContainer header={tracksGivingReasonDesire.track10} credits={`Words: Eduard Tempelten & Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track10)} />
                <TrackContainer header={tracksGivingReasonDesire.track11} credits={`Words: Stefan George`} lyrics={generateLyrics(tracksGivingReasonDesire.track11)} />
                <TrackContainer header={tracksGivingReasonDesire.track12} credits={`Words: Benjamin Sperling`} lyrics={generateLyrics(tracksGivingReasonDesire.track12)} />
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default GivingReasonDesireDetails;