import React from 'react';

export interface ContactOptionProps {
    name: string
    icon: string
    link: string
}

const ContactOption = ({ name, icon, link }: ContactOptionProps) => {
    return (
        <a href={link} rel={"noreferrer"} target={"_blank"}>
            <img id={`Icon ${name}`} className={`contactButton btnMargin`} src={icon} alt={`Contact Icon ${name}`} title={`switch to ${name}`}/>
        </a>
    );
};

export default ContactOption;