import React from 'react';
import Europa from '../../../../resources/02_Europa.mp3';

const EuropaPlayer = () => {
    return (
        <div className={`centerMe`}>
            <audio src={Europa} controls={true} />
        </div>
    );
};

export default EuropaPlayer;