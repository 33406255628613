import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_anthemsAgainstZcum.jpg";
import BackOption from "../../../BackOption";
import TrackContainer from "../../TrackContainer";
import MainMenuOption from "../../../footer/MainMenuOption";
import DosKelblPlayer from "./DosKelblPlayer";
import HeySokolyPlayer from "./HeySokolyPlayer";
import AnEuropaPlayer from "./AnEuropaPlayer";
import ShomerYisraelPlayer from "./ShomerYisraelPlayer";

enum tracksAnthemsAgainstZcum {
    track01 = "Dos Kelbl",
    track02 = "Гей соколи! / Hey sokoly!",
    track03 = "An Europa",
    track04 = "שומר ישראל / Shomer Yisrael"
}

const AnthemsAgainstZcumDetails = () => {
    const { t } = useTranslation();
    let opusName = 'anthemsAgainstZcum';

    function generateLyrics(track: string) {
        var text
        switch(track) {
            case tracksAnthemsAgainstZcum.track01: {
                text = <p>
                    Ojfn Forel ligt a Kelbl<br/>
                    ligt gebundn mit a schtrik<br/>
                    - hojch in Himl fligt a Fojgl,<br/>
                    frejt sich, drejt sich hin un ts'rik.<br/><br/>

                    Lacht der Wind in Korn,<br/>
                    lacht un lacht un lacht<br/>
                    - lacht er op a Tog a gantsn<br/>
                    un a halbe Nacht.<br/><br/>

                    Dona, dona, dona, dona,<br/>
                    dona, dona, dona, don.<br/>
                    Dona, dona, dona, dona,<br/>
                    dona, dona, dona, don.<br/><br/>

                    Schrejt dos Kelbl, sogt der Pojer:<br/>
                    "Wer-zje hejst dich sajn a Kalb?<br/>
                    Wolst gekent, doch sajn a Fojgl,<br/>
                    wolst gekent doch sajn a Schwalb!"<br/><br/>

                    Lacht der Wind in Korn,<br/>
                    lacht un lacht un lacht<br/>
                    - lacht er op a Tog a gantsn<br/>
                    un a halbe Nacht.<br/><br/>

                    Dona, dona, dona, dona,<br/>
                    dona, dona, dona, don.<br/>
                    Dona, dona, dona, dona,<br/>
                    dona, dona, dona, don.<br/><br/>

                    Bidne Kelblech tut men bindn,<br/>
                    un men schlept dem un men schecht.<br/>
                    Wer's hot Fligl, flit arojf tsu,<br/>
                    is bei kejnem nischt kejn Knecht.<br/><br/>

                    Lacht der Wind in Korn,<br/>
                    lacht un lacht un lacht<br/>
                    - lacht er op a Tog a gantsn<br/>
                    un a halbe Nacht.<br/><br/>

                    Dona, dona, dona, dona,<br/>
                    dona, dona, dona, don.<br/>
                    Dona, dona, dona, dona,<br/>
                    dona, dona, dona, don.
                    </p>
                break;
            }
            case tracksAnthemsAgainstZcum.track02: {
                text = <p>
                    Гей, десь там, де чорні води,<br/>
                    Сів на коня козак молодий.<br/>
                    Плаче молода дівчина,<br/>
                    Їде козак з України.<br/><br/>

                    Гей! Гей! Гей, соколи!<br/>
                    Оминайте гори, ліси, доли.<br/>
                    Дзвін, дзвін, дзвін, дзвіночку,<br/>
                    Степовий жайвороночку<br/>
                    Гей! Гей! Гей, соколи!<br/>
                    Оминайте гори, ліси, доли.<br/>
                    Дзвін, дзвін, дзвін, дзвіночку,<br/>
                    Мій степовий дзвін, дзвін, дзвін.<br/><br/>

                    Плаче, плаче, дівчинонька<br/>
                    Люба моя ластівонька<br/>
                    А я у чужому краю<br/>
                    Серце спокою немає.<br/><br/>

                    Гей! Гей! Гей, соколи!<br/>
                    Оминайте гори, ліси, доли.<br/>
                    Дзвін, дзвін, дзвін, дзвіночку,<br/>
                    Степовий жайвороночку<br/>
                    Гей! Гей! Гей, соколи!<br/>
                    Оминайте гори, ліси, доли.<br/>
                    Дзвін, дзвін, дзвін, дзвіночку,<br/>
                    Мій степовий дзвін, дзвін, дзвін.<br/><br/>

                    Жаль, жаль за милою,<br/>
                    За рідною стороною.<br/>
                    Жаль, жаль серце плаче,<br/>
                    Більше її не побачу.<br/><br/>

                    Меду, вина наливайте<br/>
                    Як загину поховайте<br/>
                    На далекій Україні<br/>
                    Коло милої дівчини.<br/><br/>

                    Гей! Гей! Гей, соколи!<br/>
                    Оминайте гори, ліси, доли.<br/>
                    Дзвін, дзвін, дзвін, дзвіночку,<br/>
                    Степовий жайвороночку<br/>
                    Гей! Гей! Гей, соколи!<br/>
                    Оминайте гори, ліси, доли.<br/>
                    Дзвін, дзвін, дзвін, дзвіночку,<br/>
                    Мій степовий дзвін, дзвін, дзвін.
                    </p>
                break;
            }
            case tracksAnthemsAgainstZcum.track03: {
                text = <p>
                    Der Krieg ist neu entglommen,<br/>
                    Deine Kinder sind erwacht.<br/>
                    Wir haben unser Schwert genommen<br/>
                    nach Jahren tiefer Schmach.<br/><br/>

                    Du stumme Zeugin uns'rer Klage<br/>
                    und uns'rer namenlosen Qual,<br/>
                    an Dich, Europa, richten wir die Frage:<br/>
                    Verlässt Du uns zum zweitenmal?<br/><br/>

                    Ist's nicht ein Kampf für Deine Sache,<br/>
                    ein Kampf, von jedem Flecken rein? Auf!<br/>
                    Der Falke will der Rache<br/>
                    gebenedeiter Engel sein.<br/><br/>

                    Du stumme Zeugin uns'rer Klage<br/>
                    und uns'rer namenlosen Qual,<br/>
                    an Dich, Europa, richten wir die Frage:<br/>
                    Verlässt Du uns zum zweitenmal?<br/><br/>

                    Die Saat ist reif, es rauschen Sensen,<br/>
                    wir schwingen auch für Dich den Stahl:<br/>
                    Die Augen uns von Hoffnung glänzen -<br/>
                    verlass uns nicht zum zweitenmal!<br/><br/>

                    Du stumme Zeugin uns'rer Klage<br/>
                    und uns'rer namenlosen Qual,<br/>
                    an Dich, Europa, richten wir die Frage:<br/>
                    Verlässt Du uns zum zweitenmal?<br/><br/>

                    Du liegst an alter Schuld erkranket -<br/>
                    Europa, o entsühne Dich!<br/>
                    Und schnell, solang' die Waage schwanket,<br/>
                    wirf noch Dein Herz hinein für mich.<br/><br/>

                    Du stumme Zeugin uns'rer Klage<br/>
                    und uns'rer namenlosen Qual,<br/>
                    an Dich, Europa, richten wir die Frage:<br/>
                    Verlässt Du uns zum zweitenmal?<br/><br/>

                    Dein Zaudern wäre dreifach ein Verbrechen,<br/>
                    denn dreifach ist der Feinde Zahl.<br/>
                    Für Dich und mich dies' Joch zu brechen,<br/>
                    verlass mich nicht zum zweitenmal.<br/><br/>

                    Ein wildes Meer von lohen Flammen,<br/>
                    der Zorn der ganzen Welt vereint<br/>
                    schlag' über seinem Haupt zusammen,<br/>
                    verzehr' der freien Menschen Feind.
                    </p>
                break;
            }
            case tracksAnthemsAgainstZcum.track04: {
                text = <p>
                    Im HaShem Lo Yivneh Bayit<br/>
                    Shav Amlu Bonav Bo.<br/>
                    Im HaShem Lo Yishmor Ir<br/>
                    Shav Shakad Shomer.<br/> <br/>

                    Hinei Hinei Lo Yanum<br/>
                    Lo Yanum v'Lo Yishan<br/>
                    Lo Yanum v'Lo Yishan<br/>
                    Shomer Yisrael.
                    </p>
                break;
            }
            default: {
                text = "Unsuccessfully tried to fetch lyrics for song " + track + "..."
                break;
            }
        }
        return (
            <p id={`${opusName}${track}Lyrics`} className='centerText'>
                {text}
            </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>
                    {t(`${opusName}_description`)}
                    {t(`${opusName}_details01a`)}
                    <a href={`../../../resources/anthemsAgainstZcum.zip`} download>
                        {t(`${opusName}_details01b`)}
                    </a>
                    {t(`${opusName}_details01c`)}
                </p>
                <p>
                    {t(`${opusName}_details01d`)}
                </p>
            </div>

            <Link className={"border marginBottom bodyBtn"} to={{pathname: '/img/galleryAnthemsAgainstZcum'}}><MainMenuOption label={t('galleryAnthemsAgainstZcum')}/></Link>

            <div className={`marginPicture`}>
                <TrackContainer header={tracksAnthemsAgainstZcum.track01} credits={`Yiddish trad. Words: Aaron Zeitlin. Music: Sholom Secunda.`} lyrics={generateLyrics(tracksAnthemsAgainstZcum.track01)} />
                <DosKelblPlayer/>
                <TrackContainer header={tracksAnthemsAgainstZcum.track02} credits={`Ukrainian trad.`} lyrics={generateLyrics(tracksAnthemsAgainstZcum.track02)} />
                <HeySokolyPlayer/>
                <TrackContainer header={tracksAnthemsAgainstZcum.track03} credits={`Words: Georg Herwegh, adapted by Benjamin Sperling. Music: Benjamin Sperling.`} lyrics={generateLyrics(tracksAnthemsAgainstZcum.track03)} />
                <AnEuropaPlayer/>
                <TrackContainer header={tracksAnthemsAgainstZcum.track04} credits={`Hebrew trad. Words: psalm 127:1.`} lyrics={generateLyrics(tracksAnthemsAgainstZcum.track04)} />
                <ShomerYisraelPlayer/>
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default AnthemsAgainstZcumDetails;