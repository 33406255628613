import React from 'react';
import {useTranslation} from "react-i18next";

export interface OpusProps {
    name: string
}

const OpusHeaderNoLink = ({ name }: OpusProps) => {
    const { t } = useTranslation();

    return (
        <div className={"centerMe"}>
            <h1 className={`centerText`}>{t(`${name}_displayName`)}</h1>
            <h2>{t(`${name}_category`) + ' | ' + t(`${name}_year`)}</h2>
        </div>
    );
};

export default OpusHeaderNoLink;