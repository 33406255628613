import React from 'react';
import {useTranslation} from 'react-i18next';
import SizePicker from "./SizePicker";
import {Link} from "react-router-dom";

export interface ShopItemProps {
    name: string,
    coverPic: string
}

const ShopItem = ({name, coverPic}: ShopItemProps) => {
    const {t} = useTranslation();
    const price = t(`${name}_shopPrice`)
    const shopName = t(`${name}_shopName`)
    const category = t(`${name}_shopCategory`)
    const description = t(`${name}_shopDescription`)
    const sizes = t(`${name}_shopSizes`)

    function addItem(): { price: number; shopName: string; category: string, shopId: string, amount: number } {
        return {
            shopName: shopName,
            category: category,
            price: parseInt(price),
            shopId: shopName + category,
            amount: 1
        }
    }

    function determineSizes() {
        if (category.toLowerCase().includes("shirt") && sizes) {
            return collectSizes(sizes)
        } else {
            return []
        }
    }

    function collectSizes(availableSizes: string): string[] {
        if (availableSizes) {
            return availableSizes.split(",")
        } else {
            return []
        }
    }

    function setDetailsLinkVisibility() {
        if (category.toLowerCase().includes("limit")) {
            return ""
        } else {
            return "invisible"
        }
    }

    function isSoldOut(): boolean {
        return description === "Out of stock." || description === "Nicht mehr verfügbar."
    }

    function setOpacity(): string {
        if (isSoldOut()) {
            return "opacity05"
        } else {
            return ""
        }
    }

    function setTextColour(): string {
        if (isSoldOut()) {
            return "textLightGrey"
        } else {
            return ""
        }
    }

    return (
        <div className={"fullWidth centerMe paddingBottom positionRelative"}>
            <img className={`coverPicSize marginPicture ${setOpacity()}`} src={coverPic} alt={`${name}`} title={`${name}`} />
            <h3 className={`centerText ${setTextColour()}`}>{t(`${name}_shopName`)} ({t(`${name}_shopCategory`)})</h3>
            <p className={`centerText`}>{description}</p>
            <Link className={`${setDetailsLinkVisibility()}`} to={{pathname: `/shop/${name}`}}>
                <div className={"mainMenuOption bodyBtn border width12 marginBottom"}>D E T A I L S</div>
            </Link>
            <span className={`marginsmall ${setTextColour()}`}>{price}€</span>
            <SizePicker availableSizes={determineSizes()} item={addItem()} isSoldOut={isSoldOut()}></SizePicker>
        </div>
    );
};

export default ShopItem;