import React, {useEffect, useState} from 'react';
import Concert from './Concert';

export interface ConcertsPageProps {
    concertData: Promise<string[]>
}

const ConcertsPage = ({ concertData }: ConcertsPageProps) => {
    const [concerts, setConcerts] = useState<string[]>([])

    useEffect(() => {
        concertData.then((data) => {
            setConcerts(data)
        })}, [])

    return (
        <div className={'centerMe heightInherit'}>
            {
                concerts.map((singleConcert) => {
                    // Don't ask... Without the stringify(), "Uncaught (in promise) SyntaxError: '[object Object]' is not valid JSON" is thrown
                    const concert = JSON.parse(JSON.stringify(singleConcert));
                    return (
                        <Concert venue={concert.venue} city={concert.city} country={concert.country} date={concert.date} link={concert.link} performers={concert.performers} />
                    );
                })
            }
        </div>
    );
}



export default ConcertsPage;