import React from 'react';
import ShomerYisrael from '../../../../resources/Shomer_Yisrael.mp3';

const RitesOvSummerPlayer = () => {
    return (
        <div className={`centerMe`}>
            <audio src={ShomerYisrael} controls={true} />
        </div>
    );
};

export default RitesOvSummerPlayer;