import React, {Suspense} from 'react';
import '../resources/style.css';
import mailIcon from '../resources/img/icon_mail.svg';
import welcomePic from "../resources/img/quadrat_hafen1_480.jpeg";
import {useTranslation} from "react-i18next";
import ContactOption from "./contact/ContactOption";

const WelcomePage = () => {
    const { t } = useTranslation();

    return <Suspense fallback='loading'>
        <div id={'welcomePageContainer'} className={`centerMeHorizontally heightInherit marginTopLarge`}>
            <img src={welcomePic} className='sitelogo' alt='logo'/>
            <h1 id={"welcomeHeader"}>{t('welcomeHeader')}</h1>
            <p>{t('welcomeP1')}</p>
            <p>{t('welcomeP2')}</p>
            <p>
                <ContactOption name={'Mail'} icon={mailIcon} link={'mailto:sperling@moineau.info'} />
            </p>
        </div>
    </Suspense>
};
export default WelcomePage;