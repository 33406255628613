import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../ReduxStore";

export interface CartItem {
    shopName: string,
    category: string,
    price: number,
    size?: string,
    shopId: string,
    amount: number
}

interface CartState {
    cartContent: CartItem[],
}

const initialState: CartState = {
    cartContent: [],
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action: PayloadAction<CartItem>) {
            const newItem: CartItem = {
                shopName: action.payload.shopName,
                category: action.payload.category,
                price: action.payload.price,
                size: action.payload.size,
                shopId: action.payload.shopId,
                amount: action.payload.amount
            }
            let wasItemAdded: boolean = false
            state.cartContent.forEach(item => {
                if (item.shopId === newItem.shopId) {
                    item.amount += 1
                    wasItemAdded = true
                }
            })

            if (!wasItemAdded) {
                state.cartContent.push(newItem)
            }
        },
        emptyCart(state) {
            state.cartContent = []
        },
        subtractFromCart(state, action: PayloadAction<CartItem>) {
            const itemToRemove: CartItem = {
                shopName: action.payload.shopName,
                category: action.payload.category,
                price: action.payload.price,
                size: action.payload.size,
                shopId: action.payload.shopId,
                amount: action.payload.amount
            }
            state.cartContent.forEach(item => {
                if (item.shopId === itemToRemove.shopId && item.amount > 1) {
                    item.amount -= 1
                } else if (item.shopId === itemToRemove.shopId && item.amount === 1) {
                    state.cartContent.pop()
                }
            })
        }
    }
})

export const { addToCart, subtractFromCart, emptyCart } = cartSlice.actions

export const selectCart = (state: RootState) => state.cart.cartContent

export default cartSlice.reducer