import React from 'react';
import {CartItem} from "./cart/CartSlice";
import AddToCart from "./cart/AddToCart";
import cartIcon from "../../resources/img/icon_cart.svg";

interface SizePickerProperties {
    availableSizes: string[],
    item: CartItem,
    isSoldOut: boolean
}

const SizePicker = ({ availableSizes, item, isSoldOut }: SizePickerProperties) => {
    const [sizeValue, setSizeValue] = React.useState<string>("S");
    const visibility = (availableSizes.length > 0) ? "" : "invisible"
    
    function addSizeToItem(): CartItem {
        item.size = sizeValue
        item.shopId = item.shopName + item.category + item.size
        return item
    }

    function setCartIcon(): string {
        if (isSoldOut) {
            return ""
        } else {
            return cartIcon
        }
    }

    return (
        <div className={`inlineFlex bottomRightWithinContainer`}>
            <span className={`centerMe marginRightSmall`}>
                <label className={`border ${visibility}`}>
                    <select className={`noStyle`} value={sizeValue} onChange={e => setSizeValue(e.target.value)}>
                        {availableSizes.map(size => {
                            return <option value={size}>{size}</option>
                        })}
                    </select>
                </label>
            </span>
            <AddToCart item={addSizeToItem()} icon={setCartIcon()}></AddToCart>
        </div>
    );
};

export default SizePicker;