import React from 'react';
import DeTvijSpasitel from '../../../../resources/01_De_tvij_spasitel.mp3';

const DeTvijSpasitelPlayer = () => {
    return (
        <div className={`centerMe`}>
            <audio src={DeTvijSpasitel} controls={true} />
        </div>
    );
};

export default DeTvijSpasitelPlayer;