import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from '../../../../resources/img/pics/works/releases_cover_unioSolidarium.jpg';
import BackOption from "../../../BackOption";
import TrackContainer from "../../TrackContainer";
import ContactOption from "../../../contact/ContactOption";
import iconFacebook from "../../../../resources/img/icon_facebook.svg";
import iconBandcamp from "../../../../resources/img/icon_bandcamp.svg";
import iconForward from "../../../../resources/img/icon_forward.svg";

const UnioSolidariumDetails = () => {
    const { t } = useTranslation();
    let opusName = 'unioSolidarium';

    function generateLyrics() {
        return (
          <p id={`${opusName}Lyrics`} className='centerText'>
              Hab aller czweifel aynen nicht,<br/>
              ich wil dem geleichen nyndert han,<br/>
              hercz, muet, gedangk und sin domit<br/>
              wil ich dir, fraw czu lecze lan,<br/>
              wenn du pist sicherleich dy erst<br/>
              von der ich freud in herczen gewan.<br/>
              Ich traw dir wol, das du mich gewerst<br/>
              und mich in herczen nicht verkerst,<br/>
              wann ich mich dir am pesten gan.<br/> <br/>

              Ich wil darumb halt nicht geschaiden sein,<br/>
              ob sich mein wesen schait von dir,<br/>
              ewikleich pist du das mein<br/>
              und traw auch, fraw, der güete dein,<br/>
              du tuest also des geleichen mir.<br/> <br/>

              Du pist die erst an angefangk,<br/>
              als ich dirs vor han gesait,<br/>
              nu beleib dy leczt an abegangk;<br/>
              seit sich mein wesen von dir schait,<br/>
              tausentstund gesegen dich got!<br/>
              Mein liebste fraw, nu wünsch mir hail,<br/>
              das ich mich schaid, das machet not,<br/>
              doch beleibt dir das hercz an allen spot,<br/>
              dein gueten willen mit mir tail.<br/> <br/>

              Ich wil darumb halt nicht geschaiden sein,<br/>
              ob sich mein wesen schait von dir,<br/>
              ewikleich pist du das mein<br/>
              und traw auch, fraw, der güete dein,<br/>
              du tuest also des geleichen mir.<br/> <br/>

              Mein liebste fraw, geselle guet,<br/>
              schaid dich von mir als ich dich pitt,<br/>
              (man spricht:) wer aus den augen, der aus dem muet,<br/>
              des main ich sicherleichen nit,<br/>
              des peleib an mir stät unde war.<br/>
              Des gib ich dir czu pfant mein trew<br/>
              gar unverkert von jar czu jar,<br/>
              die weil ich leb auf erden czwar -<br/>
              dein wil ich sein an alls gerew.<br/> <br/>

              Ich wil darumb halt nicht geschaiden sein,<br/>
              ob sich mein wesen schait von dir,<br/>
              ewikleich pist du das mein<br/>
              und traw auch, fraw, der güete dein,<br/>
              du tuest also des geleichen mir.
          </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>{t(`${opusName}_description`)}</p>
                <p>{t(`${opusName}_details01`)}</p>
                <div className={'centerMe'}>
                    <p>
                        <ContactOption name={"Facebook"} icon={iconFacebook} link={"https://www.facebook.com/Old-Captain-175993889160335"} />
                        <ContactOption name={"Old Captain's website"} icon={iconForward} link={'http://oldcaptain.com.ua'} />
                        <ContactOption name={"Bandcamp"} icon={iconBandcamp} link={"https://oldcaptain.bandcamp.com/album/v-a-unio-solidarium?fbclid=IwAR12tDzWkaGFOsRCHTojBJFulV3AopclPwlDNz1p9QPzil-f5oXUExUgWvE"} />
                    </p>
                </div>
            </div>
            <div className={'marginPicture'}>
                <TrackContainer header={`There Was Silence In Heaven`} credits={`Desiderii Marginis`} lyrics={null} />
                <TrackContainer header={`ViperSoul`} credits={`Of The Wand And The Moon`} lyrics={null} />
                <TrackContainer header={`You & I, Shall Never (Ever) Die (An Epitaph 4 Albin)`} credits={`Ordo Rosarius Equilibrio`} lyrics={null} />
                <TrackContainer header={`Ah! Sunflower`} credits={`In Ruin`} lyrics={null} />
                <TrackContainer header={`Vaterland`} credits={`Die Weiße Rose`} lyrics={null} />
                <TrackContainer header={`Insanity`} credits={`Brighter Death Now`} lyrics={null} />
                <TrackContainer header={`Underground Of Ironworks`} credits={`Linekraft`} lyrics={null} />
                <TrackContainer header={`Closer To The Grave`} credits={`Sophia`} lyrics={null} />
                <TrackContainer header={`You Must Shoot Before Asking`} credits={`Moral Order`} lyrics={null} />
                <TrackContainer header={`World Of Suffering`} credits={`Deathstench`} lyrics={null} />
                <TrackContainer header={`Vortex Of Will`} credits={`Grunt`} lyrics={null} />
                <TrackContainer header={`To The Last`} credits={`Morthound`} lyrics={null} />
                <TrackContainer header={`Crimson Clover`} credits={`Sieben`} lyrics={null} />
                <TrackContainer header={`Dirge For Animal Death`} credits={`Blitzkrieg Baby`} lyrics={null} />
                <TrackContainer header={`Speech From The Shadows (II)`} credits={`New Risen Throne & Konau`} lyrics={null} />
                <TrackContainer header={`Red Skies`} credits={`Vestigial`} lyrics={null} />
                <TrackContainer header={`Fourteenth Ornament`} credits={`Empusae & Shinkiro`} lyrics={null} />
                <TrackContainer header={`Warrior Creed`} credits={`Apoptose & Joy Of Life`} lyrics={null} />
                <TrackContainer header={`Auferstehung (Reprise)`} credits={`Wappenbund`} lyrics={null} />
                <TrackContainer header={`Descending Into A Dream`} credits={`Mortiis`} lyrics={null} />
                <TrackContainer header={`A Place Where We Never Want To Be`} credits={`Galerie Schallschutz`} lyrics={null} />
                <TrackContainer header={`Throne Of Blood`} credits={`Bain Wolfkind`} lyrics={null} />
                <TrackContainer header={`Zhakhlyvo`} credits={`Theologian`} lyrics={null} />
                <TrackContainer header={`Genom Skändligt Förfall`} credits={`Alfarmania`} lyrics={null} />
                <TrackContainer header={`It Happened Between Mojsen and The Steel Shed`} credits={`Bocksholm`} lyrics={null} />
                <TrackContainer header={`Kuolemaa Ne Hokee`} credits={`Kaarna`} lyrics={null} />
                <TrackContainer header={`Hab aller czweifel aynen nicht`} credits={'moineau. Words: ' + t(`${opusName}_credits`)} lyrics={generateLyrics()} />
                <TrackContainer header={`Meditation On Death `} credits={`Sacral Wound`} lyrics={null} />
                <TrackContainer header={`Godhead`} credits={`Reserva Espiritual De Occidente`} lyrics={null} />
                <TrackContainer header={`Nirvana`} credits={`Twa Corbies`} lyrics={null} />
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default UnioSolidariumDetails;