import React from 'react';
import OpusHeaderLink from "../../OpusHeaderLink";
import coverPic from '../../../../resources/img/pics/works/releases_cover_deProfundisCantavi.jpg';

const DeProfundisCantavi = () => {
    let opusName = 'deProfundisCantavi';

    return (
        <div className={`centerMe marginPicture`}>
            <img className={`coverPicSize marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderLink name={opusName}/>
        </div>
    );
};

export default DeProfundisCantavi;