import React from 'react';
import coverPic from "../../../../resources/img/pics/works/releases_cover_ritesOvSummer.jpg";
import OpusHeaderLink from "../../OpusHeaderLink";

const RitesOvSummer = () => {
    let opusName = 'ritesOvSummer';

    return (
        <div className={`centerMe marginPicture`}>
            <img className={`coverPicSize marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderLink name={opusName}/>
        </div>
    );
};

export default RitesOvSummer;