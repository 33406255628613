import React from 'react';
import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/AboutPage";
import ContactPage from "./components/contact/ContactPage";
import WelcomePage from "./components/WelcomePage";
import WorksPage from "./components/works/WorksPage";
import AnthemsAgainstZcumDetails from "./components/works/instances/anthemsAgainstZcum/AnthemsAgainstZcumDetails";
import DeProfundisCantaviDetails from "./components/works/instances/deProfundisCantavi/DeProfundisCantaviDetails";
import VoxVerisDetails from "./components/works/instances/voxVeris/VoxVerisDetails";
import RagnaroekDetails from "./components/works/instances/ragnaroek/RagnaroekDetails";
import RitesOvSummerDetails from "./components/works/instances/ritesOvSummer/RitesOvSummerDetails";
import GivingReasonDesireDetails from "./components/works/instances/givingReasonDesire/GivingReasonDesireDetails";
import ImagePage from "./components/gallery/ImagePage";
import ConcertsPage from "./components/concerts/ConcertsPage";
import ShopPage from "./components/shop/ShopPage";
import GivingReasonDesireBoxPage from "./components/shop/GivingReasonDesireBoxPage";
import SeredSokolivPage from "./components/shop/SeredSokolivPage";
import UnioSolidariumDetails from "./components/works/instances/unioSolidarium/UnioSolidariumDetails";
import axios from "axios";
import AlbionsBeautifulBarbariansDetails from './components/works/instances/albionsBeautifulBarbarians/AlbionsBeautifulBarbariansDetails';
import MoineauWestwindDetails from "./components/works/instances/moineauWestwind/MoineauWestwindDetails";
import DeTvijSpasitelDetails from "./components/works/instances/deTvijSpasitel/DeTvijSpasitelDetails";

const AppRoutes = () => (
    <Routes>
        <Route path={'/'} element={<WelcomePage/>}/>
        <Route path={'/about'} element={<AboutPage/>}/>
        <Route path={'/contact'} element={<ContactPage/>}/>
        <Route path={'/concerts'} element={<ConcertsPage concertData={getConcertData()} />}/>
        <Route path={'/img/galleryAnthemsAgainstZcum'} element={<ImagePage gallery={"anthemsAgainstZcum"} numberOfPictures={1}/>}/>
        <Route path={'/img/galleryGivingReasonDesire'} element={<ImagePage gallery={"givingReasonDesire"} numberOfPictures={20}/>}/>
        <Route path={'/img/galleryImpressions'} element={<ImagePage gallery={"impressions"} numberOfPictures={6}/>}/>
        <Route path={'/shop'} element={<ShopPage/>}/>
        <Route path={'/shop/givingReasonDesireBox'} element={<GivingReasonDesireBoxPage/>}/>
        <Route path={'/shop/shirtSeredSokoliv'} element={<SeredSokolivPage/>}/>
        <Route path={'/works'} element={<WorksPage/>}/>
        <Route path={'/works/albionsBeautifulBarbarians'} element={<AlbionsBeautifulBarbariansDetails/>}/>
        <Route path={'/works/anthemsAgainstZcum'} element={<AnthemsAgainstZcumDetails/>}/>
        <Route path={'/works/deProfundisCantavi'} element={<DeProfundisCantaviDetails/>}/>
        <Route path={'/works/deTvijSpasitel'} element={<DeTvijSpasitelDetails/>}/>
        <Route path={'/works/givingReasonDesire'} element={<GivingReasonDesireDetails/>}/>
        <Route path={'/works/moineauWestwind'} element={<MoineauWestwindDetails/>}/>
        <Route path={'/works/ragnaroek'} element={<RagnaroekDetails/>}/>
        <Route path={'/works/ritesOvSummer'} element={<RitesOvSummerDetails/>}/>
        <Route path={'/works/unioSolidarium'} element={<UnioSolidariumDetails/>}/>
        <Route path={'/works/voxVeris'} element={<VoxVerisDetails/>}/>
    </Routes>
);

async function getConcertData(): Promise<string[]> {
        try {
                const { data } = await axios.get(
                    '/concertdata',
                    {
                            headers: {
                                    'Access-Control-Allow-Origin': '*',
                                    Accept: 'text/html',
                            }
                    }
                );
                return data;
        } catch (error) {
                if (axios.isAxiosError(error)) {
                        console.log('error message: ', error.message);
                        return [error.message];
                } else {
                        console.log('unexpected error: ', error);
                        return ['An unexpected error occurred'];
                }
        }
}

export default AppRoutes;