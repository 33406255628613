import React, {Suspense, useState} from 'react';
import {useTranslation} from "react-i18next";
import '../resources/style.css';
import iconDe from '../resources/img/icon_de.jpeg';
import iconEn from '../resources/img/icon_en.jpeg';

const LanguageSelector = () => {
    const { i18n } = useTranslation()
    const [selectedLanguage, setSelectedLanguage] = useState<string>("en")

    function getCurrentLanguageIcon() {
        if(selectedLanguage === 'de') return iconDe
        else return iconEn
    }

    const languageIcon = getCurrentLanguageIcon()

    const changeLanguageHandler = () => {
        if (selectedLanguage === 'en') setLanguage('de')
        else setLanguage('en')
    }

    function setLanguage(lang: string) {
        i18n.changeLanguage(lang)
        setSelectedLanguage(lang)
    }

    return <div>
        <button className={`noStyle`} onClick={() => {changeLanguageHandler()}}>
            <img id={`${selectedLanguage}Icon`} className={`iconSize roundCorners bottomLeft`} src={languageIcon} alt={`Selected Language Icon`} title={`Icon ${selectedLanguage}`}/>
        </button>
    </div>
};

export default function App() {
    return (
        <Suspense fallback="loading">
            <LanguageSelector />
        </Suspense>
    );
}