import React from 'react';
import {useTranslation} from "react-i18next";
import vogerl from '../resources/img/vogerlKlein.svg';
import MainMenuOption from "./footer/MainMenuOption";
import {Link} from "react-router-dom";

const AboutPage = () => {
    const { t } = useTranslation();

    return (
        <div className={"centerMeHorizontally heightInherit marginTopLarge"}>
            <img className={""} style={{
                maxHeight: "8em",
                margin: "2em"}} src={vogerl} alt={"Vogerl"} title={"Vogerl"}/>
            <h1>{t('aboutHeader1A')}</h1>
            <h1>{t('aboutHeader1B')}</h1>
            <p>{t('aboutP1')}</p>
            <h1>{t('aboutHeader2')}</h1>
            <p>{t('aboutP2')}</p>
            <h1>{t('aboutHeader3')}</h1>
            <p>{t('aboutP3')}</p>
            <Link className={"border marginSmall bodyBtn"} to={{pathname: '/img/galleryImpressions'}}><MainMenuOption label={t('galleryImpressions')}/></Link>
            <Link className={"border marginBottom bodyBtn"} to={{pathname: '/img/galleryGivingReasonDesire'}}><MainMenuOption label={t('galleryGivingReasonDesire')}/></Link>
            <h1>{t('aboutHeader4')}</h1>
            <p>{t('aboutP4')}</p>
        </div>
    );
};

export default AboutPage;