import React from 'react';
import {Link} from "react-router-dom";
import BackOption from "../BackOption";
import {useTranslation} from "react-i18next";
import albumPic from "../../resources/img/pics/shop/givingReasonDesireBox_albumPic.jpg";
import notebookPic from "../../resources/img/pics/shop/givingReasonDesireBox_NotebookPic.jpg";
import moineauMortPic from "../../resources/img/pics/shop/givingReasonDesireBox_moineauMortPic.jpg";
import cardPic from "../../resources/img/pics/shop/givingReasonDesireBox_cardPic.jpg";
import boxPic from "../../resources/img/pics/shop/givingReasonDesireBox_boxPic.jpg";

const GivingReasonDesireBoxPage = () => {
    const { t } = useTranslation();
    let opusName = 'givingReasonDesireBox';

    return (
        <div className={`centerMe`}>
            <h1 className={`marginTop`}>{t(`${opusName}_detailHeader`)}</h1>
            <p>{t(`${opusName}_detailP1`)}</p>
            <h2>{t(`${opusName}_detailContentHeader`)}</h2>
            <p>{t(`${opusName}_detailP2`)}</p>
            <img className={`coverPicSize marginPicture`} src={albumPic} alt={`The album.`} title={`The album.`} />
            <p>{t(`${opusName}_detailP3`)}</p>
            <img className={`coverPicSize marginPicture`} src={notebookPic} alt={`Notebook and pen.`} title={`Notebook and pen.`} />
            <p>{t(`${opusName}_detailP4`)}</p>
            <img className={`coverPicSize marginPicture`} src={moineauMortPic} alt={`The moineau mort.`} title={`The moineau mort.`} />
            <p>{t(`${opusName}_detailP5`)}</p>
            <img className={`coverPicSize marginPicture`} src={cardPic} alt={`Art print and poem.`} title={`Art print and poem.`} />
            <p>{t(`${opusName}_detailP6`)}</p>
            <img className={`coverPicSize marginPicture`} src={boxPic} alt={`The box.`} title={`The box.`} />
            <p>{t(`${opusName}_detailP7`)}</p>
            <Link to={{pathname: '/shop'}}><BackOption /></Link>
        </div>
    );
};

export default GivingReasonDesireBoxPage;