import React from 'react';
import DeProfundisCantavi from "./instances/deProfundisCantavi/DeProfundisCantavi";
import VoxVeris from "./instances/voxVeris/VoxVeris";
import AnthemsAgainstZcum from "./instances/anthemsAgainstZcum/AnthemsAgainstZcum";
import Ragnaroek from "./instances/ragnaroek/Ragnaroek";
import RitesOvSummer from "./instances/ritesOvSummer/RitesOvSummer";
import GivingReasonDesire from "./instances/givingReasonDesire/GivingReasonDesire";
import UnioSolidarium from "./instances/unioSolidarium/UnioSolidarium";
import AlbionsBeautifulBarbarians from './instances/albionsBeautifulBarbarians/AlbionsBeautifulBarbarians';
import MoineauWestwind from "./instances/moineauWestwind/MoineauWestwind";
import DeTvijSpasitel from "./instances/deTvijSpasitel/DeTvijSpasitel";

const WorksPage = () => {
    return (
        <div className={"centerMeHorizontally heightInherit marginTopLarge"}>
            <MoineauWestwind />
            <DeTvijSpasitel />
            <AlbionsBeautifulBarbarians />
            <AnthemsAgainstZcum />
            <UnioSolidarium />
            <GivingReasonDesire />
            <VoxVeris />
            <Ragnaroek />
            <DeProfundisCantavi />
            <RitesOvSummer />
        </div>
    );
};

export default WorksPage;