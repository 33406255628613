import React from 'react';
import track from '../../../../resources/09_Daemmerung.mp3';

const RagnaroekPlayer = () => {
    return (
        <div className={`centerMe`}>
            <audio src={track} controls={true} />
        </div>
    );
};

export default RagnaroekPlayer;