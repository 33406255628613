import React from 'react';
import '../resources/style.css';
import LanguageSelector from './LanguageSelector';
import MainMenuButton from "./footer/MainMenuButton";
import ShoppingCart from "./shop/cart/ShoppingCart";

const Footer = () => {
    return <div id={"footer"} className={`bottom centerMe fullWidth`}>
        <LanguageSelector></LanguageSelector>
        <ShoppingCart></ShoppingCart>
        <MainMenuButton></MainMenuButton>
    </div>
};

export default Footer