import React from 'react';

export interface MainMenuOptionProps {
    label: string
}

const MainMenuOption = ({ label }: MainMenuOptionProps) => {
    return (
        <div className={"mainMenuOption"}>
            {label}
        </div>
    );
};

export default MainMenuOption;