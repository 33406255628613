import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_moineauWestwind.jpg";
import BackOption from "../../../BackOption";
import TrackContainer from "../../TrackContainer";
import MainMenuOption from "../../../footer/MainMenuOption";

const MoineauWestwindDetails = () => {
    const { t } = useTranslation();
    let opusName = 'moineauWestwind';

    function generateLyrics() {
        return (
            <p>
                Гей, десь там, де чорні води,<br/>
                Сів на коня козак молодий.<br/>
                Плаче молода дівчина,<br/>
                Їде козак з України.<br/><br/>

                Гей! Гей! Гей, соколи!<br/>
                Оминайте гори, ліси, доли.<br/>
                Дзвін, дзвін, дзвін, дзвіночку,<br/>
                Степовий жайвороночку<br/>
                Гей! Гей! Гей, соколи!<br/>
                Оминайте гори, ліси, доли.<br/>
                Дзвін, дзвін, дзвін, дзвіночку,<br/>
                Мій степовий дзвін, дзвін, дзвін.<br/><br/>

                Плаче, плаче, дівчинонька<br/>
                Люба моя ластівонька<br/>
                А я у чужому краю<br/>
                Серце спокою немає.<br/><br/>

                Гей! Гей! Гей, соколи!<br/>
                Оминайте гори, ліси, доли.<br/>
                Дзвін, дзвін, дзвін, дзвіночку,<br/>
                Степовий жайвороночку<br/>
                Гей! Гей! Гей, соколи!<br/>
                Оминайте гори, ліси, доли.<br/>
                Дзвін, дзвін, дзвін, дзвіночку,<br/>
                Мій степовий дзвін, дзвін, дзвін.<br/><br/>

                Жаль, жаль за милою,<br/>
                За рідною стороною.<br/>
                Жаль, жаль серце плаче,<br/>
                Більше її не побачу.<br/><br/>

                Меду, вина наливайте<br/>
                Як загину поховайте<br/>
                На далекій Україні<br/>
                Коло милої дівчини.<br/><br/>

                Гей! Гей! Гей, соколи!<br/>
                Оминайте гори, ліси, доли.<br/>
                Дзвін, дзвін, дзвін, дзвіночку,<br/>
                Степовий жайвороночку<br/>
                Гей! Гей! Гей, соколи!<br/>
                Оминайте гори, ліси, доли.<br/>
                Дзвін, дзвін, дзвін, дзвіночку,<br/>
                Мій степовий дзвін, дзвін, дзвін.
            </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>
                    {t(`${opusName}_description`)}
                </p>
                <p className={`centerMe`}>
                    {t(`${opusName}_details`)}<br/><br/>
                    <a href={`https://www.steelwork.fr/steelwork-maschine-releases/2937-moineauwestwind-hej-sokoly-zasvit-vstaly-kozachenky-7-.html`} rel={"noreferrer"} target={"_blank"}>
                        Steelwork Maschine
                    </a>
                </p>
            </div>

            <div className={`marginPicture`}>
                <TrackContainer header={`Гей соколи! / Hey sokoly!`} credits={`Another interpretation of the Ukrainian trad by moineau.`} lyrics={generateLyrics()} />
                <TrackContainer header={`Засвіт встали козаченьки / Zasvít vstály kozachenky`} credits={`Ukrainian trad, interpreted by Westwind.`} lyrics={null} />
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default MoineauWestwindDetails;