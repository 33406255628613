import React, {useEffect} from 'react';

export interface TrackContainerProps {
    header: string,
    credits: string,
    lyrics: JSX.Element | null
}

const TrackContainer = ({header, credits, lyrics}: TrackContainerProps) => {
    const [visibilityLyrics, setVisibilityLyrics] = React.useState('invisible')
    const [areThereLyrics, setAreThereLyrics] = React.useState('visible')
    let lyricsId = `${header}Lyrics`;

    useEffect(() => {
        if (lyrics == null) {
            setAreThereLyrics('invisible')
        } else {
            setAreThereLyrics('visible')
        }
    }, [lyrics]);

    function toggleVisibility() {
        if (visibilityLyrics === 'invisible') {
            setVisibilityLyrics('visible');
        } else {
            setVisibilityLyrics('invisible');
        }
    }

    return (
        <div className={`centerMe marginPicture`}>
            <h3 className={`centerText`}>{header}</h3>
            <h4 className={`centerText`}>{credits}</h4>
            <button className={`mainMenuOption bodyBtn border ${areThereLyrics}`} onClick={() => toggleVisibility()}>L Y R I C S</button>
            <div id={lyricsId} className={`${visibilityLyrics}`}>
                {lyrics}
            </div>
        </div>
    );
};

export default TrackContainer;