import React from 'react';
import {Link} from "react-router-dom";
import BackOption from "../BackOption";
import {useTranslation} from "react-i18next";
import shirtPic1 from "../../resources/img/pics/shop/shirtSeredSokoliv_01.jpg";
import shirtPic2 from "../../resources/img/pics/shop/shirtSeredSokoliv_02.jpg";

const GivingReasonDesireBoxPage = () => {
    const { t } = useTranslation();
    let opusName = 'shirtSeredSokoliv';

    return (
        <div className={`centerMe`}>
            <h1 className={`marginTop`}>{t(`${opusName}_detailHeader`)}</h1>
            <p>{t(`${opusName}_detailP1`)}</p>
            <img className={`coverPicSize marginPicture`} src={shirtPic1} alt={`The shirt.`} title={`Sered Sokoliv.`} />
            <img className={`coverPicSize marginPicture`} src={shirtPic2} alt={`The shirt.`} title={`Sered Sokoliv.`} />
            <p>{t(`${opusName}_detailP2`)}</p>
            <p>{t(`${opusName}_detailP3`)}</p>
            <Link to={{pathname: '/shop'}}><BackOption /></Link>
        </div>
    );
};

export default GivingReasonDesireBoxPage;