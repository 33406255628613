import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_albionsBeautifulBarbarians.jpg";
import goToIcon from '../../../../resources/img/icon_forward.svg';
import TrackContainer from "../../TrackContainer";
import BackOption from "../../../BackOption";

const AlbionsBeautifulBarbarians = () => {
    const { t } = useTranslation();
    let opusName = 'albionsBeautifulBarbarians';

    function generateLyrics() {
        return (
            <p id={`${opusName}Lyrics`} className='centerText'>
                Weiße, weiße Blüten tauen<br/>
                drum fasse Schritt, drum reich' die Hand<br/>
                Frühling kommt im maienblauen<br/>
                strahlenden Gewand.<br/> <br/>

                Müde, müde Glieder beben<br/>
                Sehnsucht tränkt den hehren Geist<br/>
                schlägt den Funken, lässt uns streben<br/>
                wohin sein Licht uns weist.<br/> <br/>

                Junge, junge Kehlen klingen<br/>
                vom Lied wider die Alte Welt,<br/>
                deren Schlingen zu entringen<br/>
                all ihr Trachten gilt.<br/> <br/>

                Hellen, hellen Sanges drängt<br/>
                die Jugend in das off'ne Land -<br/>
                Abend dämmert, Sonne sinkt<br/>
                ferne rauscht ein Brand.<br/> <br/>

                Blüten blühen, Blüten gilben<br/>
                das Los, dem sie entgegenleben<br/>
                kann kein Schwur erfüllen,<br/>
                kann sie nicht erheben.<br/> <br/>

                Stumme, stumme Stimmen schweigen<br/>
                der Herbstwind reißt am Leinentuch<br/>
                das letzte Grün schickt sich zu neigen<br/>
                dem äußersten Gesuch.<br/> <br/>

                Auf alten Wegen wandernd, stille<br/>
                zu Häupten Himmel wolkenschwer<br/>
                Stirn deckt Sturmes wilde Fülle<br/>
                wirbelt rauschend Wiederkehr.<br/> <br/>

                Blüten blühen, Blüten gilben<br/>
                das Los, dem sie entgegenleben<br/>
                kann kein Schwur erfüllen,<br/>
                kann sie nicht erheben.<br/> <br/>

                Schwarze, schwarze Linnen kleiden<br/>
                ein Geschlecht, des' Puls erlosch,<br/>
                das im Strome der Gezeiten<br/>
                nimmer ist - und doch.
            </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>
                    {t(`${opusName}_description`)}
                </p>
                <p>
                    {t(`${opusName}_details01a`)}
                </p>
                <p className={'centerMe'}>
                    <a href={`https://tigernet.no/releases/952226-kibbo-kift?fbclid=IwAR3aU75KnLF8T3GJjb1uEguBGCNG47yFcvuDEUyvm6AZ65Rpeayp7BMkqP8`}>
                        <img id={`IconToShop`} className={`contactButton btnMargin`} src={goToIcon}
                             alt={`Icon to webshop`} title={`switch to webshop`}/>
                    </a>
                </p>
            </div>
            <div className={`marginPicture`}>
                <TrackContainer header={`The Inauguration of the Kibbo kift`} credits={`Ulver`} lyrics={null} />
                <TrackContainer header={`Auf!`} credits={`Jännerwein`} lyrics={null} />
                <TrackContainer header={`Children of the Fire`} credits={`Sol Invictus`} lyrics={null} />
                <TrackContainer header={`Woodcraft`} credits={`Blood and Sun`} lyrics={null} />
                <TrackContainer header={`Lied wider die Alte Welt`} credits={`moineau`} lyrics={generateLyrics()} />
                <TrackContainer header={`A New Earth Beneath New Heavens`} credits={`TenHornedBeast`} lyrics={null} />
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default AlbionsBeautifulBarbarians;