import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_voxVeris.jpg";
import BackOption from "../../../BackOption";

const VoxVerisDetails = () => {
    const { t } = useTranslation();
    let opusName = 'voxVeris';

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>{t(`${opusName}_description`)}</p>
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default VoxVerisDetails;