import React from 'react';
import ContactOption from '../contact/ContactOption';
import goToIcon from '../../resources/img/icon_forward.svg';
import {useTranslation} from "react-i18next";

export interface ConcertProps {
    venue: string,
    city: string,
    country: string,
    date: string,
    link: string,
    performers: string,
}

function Concert({venue, city, country, date, link, performers}: ConcertProps) {
    const { t } = useTranslation();

    return (
        <div className={'centerMe marginTop marginBottom'}>
            <h2 className={'redText'}>{city} ({country})</h2>
            <h3 className={'marginSmall'}>{venue}</h3>
            <h3>{date}</h3>
            {setFellowArtists(performers, t)}
            {setLink(link)}
        </div>
    );
}

function setLink(link: string) {
    let linkToWww = undefined;
    if (link.length > 0) {
        linkToWww = <ContactOption name={'event page'} icon={goToIcon} link={link}/>;
    }
    return linkToWww;
}

function setFellowArtists(performers: string, translator: Function) {
    let fellowArtists = undefined;
    if (performers.length > 0) {
        fellowArtists = <div className={'centerMe'}><h4>{translator('concertsWith')}</h4><h3>{performers}</h3></div>;
    }
    return fellowArtists;
}

export default Concert;