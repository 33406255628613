import React from 'react';
import '../../resources/style.css';
import iconFacebook from '../../resources/img/icon_facebook.svg';
import iconInstagram from '../../resources/img/icon_instagram.svg';
import iconYouTube from '../../resources/img/icon_youtube.svg';
import iconSpotify from '../../resources/img/icon_spotify.svg';
import iconBandcamp from '../../resources/img/icon_bandcamp.svg';
import iconMail from '../../resources/img/icon_mail.svg';
import iconLegal from '../../resources/img/icon_legal.svg';
import ContactOption from "./ContactOption";

const ContactPage = () => {
    return (
        <div className={"centerMe heightInherit"}>
            <ContactOption name={"Facebook"} icon={iconFacebook} link={"https://www.facebook.com/moineau-323154867715403/"}/>
            <ContactOption name={"Instagram"} icon={iconInstagram} link={"https://www.instagram.com/moineau_folk/"}/>
            <ContactOption name={"YouTube"} icon={iconYouTube} link={"https://www.youtube.com/channel/UC0lqORIxPeIlTHaeJdUfETQ"}/>
            <ContactOption name={"Spotify"} icon={iconSpotify} link={"https://open.spotify.com/artist/0FHo6f0EYv6ro6oBTV9gNz?si=NgdIEJFyRJ6QW4q5qGfxXA"}/>
            <ContactOption name={"Bandcamp"} icon={iconBandcamp} link={"https://moineaufolk.bandcamp.com/"}/>
            <ContactOption name={"Mail"} icon={iconMail} link={"mailto:sperling@moineau.info"}/>
            <ContactOption name={"Legal Matters"} icon={iconLegal} link={"https://youtu.be/-YFSRUXdua8"}/>
        </div>
    );
};

export default ContactPage;