import burgerIcon from '../../resources/img/icon_menu.svg';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MainMenuOptions from "./MainMenuOptions";

const MainMenuButton = () => {

    return <div className={"centerMe"}>
                <button id={"mainMenu"} className={"menuButton"} onClick={() => {toast(<MainMenuOptions/>)}}>
                    <img src={burgerIcon} alt={"main menu"} />
                </button>
            </div>
};

export default MainMenuButton
