import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_ragnaroek.jpg";
import RagnaroekPlayer from "./RagnaroekPlayer";
import TrackContainer from "../../TrackContainer";
import BackOption from "../../../BackOption";

const RagnaroekDetails = () => {
    const { t } = useTranslation();
    let opusName = 'ragnaroek';

    function generateLyrics() {
        return (
            <p id={`${opusName}Lyrics`} className='centerText'>
                Der Abend blutet in das Meer<br/>
                legt sich auf die Menschenseele<br/>
                rinnt wie Wein durch Aug' und Kehle<br/>
                und die Wellen rollen schwer.<br/> <br/>

                Ein ferner Vogel zieht vorüber<br/>
                dem Hort der Winde stumm entgegen<br/>
                der graue Wand'rer steht entlegen<br/>
                blickt auf seine Hände nieder.<br/> <br/>

                Die Norne tastet wie im Traum<br/>
                über ihr verheißend' Band<br/>
                raunt ihr Los ins dunkle Land<br/>
                im Schatten unter'm Eibenbaum.<br/> <br/>

                Der Wind trägt flüsternd ihre Weise<br/>
                über schwarze Wassermassen<br/>
                um den Wanderer zu fassen<br/>
                zu geleiten seine Reise.<br/> <br/>

                Ein letzter, matter Blick zum Meere<br/>
                wo die Sonne sich nun senkt<br/>
                die Brandung pulst, das Haupt gesenkt<br/>
                mischen Wogen sich und Zähren.
            </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>{t(`${opusName}_description`)}
                    {t(`${opusName}_details01a`)}
                    <a href={`https://www.discogs.com/Various-Ragnar%C3%B6k/release/10246916`}>
                        {t(`${opusName}_details01b`)}
                    </a>
                    {t(`${opusName}_details01c`)}
                    <a href={`../../../resources/09_Daemmerung.mp3.zip`} download>
                        {t(`${opusName}_details01d`)}
                    </a>
                    {t(`${opusName}_details01e`)}
                </p>
                <RagnaroekPlayer />
            </div>
            <div className={`marginPicture`}>
                <TrackContainer header={`Dämmerung`} credits={`Words: Benjamin Sperling`} lyrics={generateLyrics()} />
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default RagnaroekDetails;