import React from 'react';
import DosKelbl from '../../../../resources/Dos_Kelbl.mp3';

const RitesOvSummerPlayer = () => {
    return (
        <div className={`centerMe`}>
            <audio src={DosKelbl} controls={true} />
        </div>
    );
};

export default RitesOvSummerPlayer;