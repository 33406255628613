import {useAppDispatch} from "../../../ReduxStore";
import {addToCart} from "./CartSlice";
import {CartItem} from "./CartSlice";
import React from "react";
import {useTranslation} from "react-i18next";

interface AddToCartProps {
    item: CartItem,
    icon: string
}

export function AddToCart({item, icon}: AddToCartProps) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    function add() {
        dispatch(addToCart(item))
    }

    function setButtonImage() {
        if (icon.length > 1) {
            return <img className={`iconSize`} src={icon} alt={`cart symbol`} title={t(`shopAddToCart`)}/>
        } else {
            return icon
        }
    }

    return (
        <button onClick={add} className={`noStyle cursorPointer`}>
            {setButtonImage()}
        </button>
    )
}

export default AddToCart