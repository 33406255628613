import React from 'react';
import HeySokoly from '../../../../resources/Hey_sokoly.mp3';

const RitesOvSummerPlayer = () => {
    return (
        <div className={`centerMe`}>
            <audio src={HeySokoly} controls={true} />
        </div>
    );
};

export default RitesOvSummerPlayer;