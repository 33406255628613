import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export interface OpusProps {
    name: string
}

const OpusHeaderLink = ({ name }: OpusProps) => {
    const { t } = useTranslation();

    return (
        <div className={"centerMe"}>
            <Link to={{pathname: `/works/${name}`}}>
                <h1 className={`centerText`}>{t(`${name}_displayName`)}</h1>
            </Link>
            <h2>{t(`${name}_category`) + ' | ' + t(`${name}_year`)}</h2>
        </div>
    );
};

export default OpusHeaderLink;