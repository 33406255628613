import React from 'react';
import background from './resources/img/moineauSchriftzugVertikal.svg';
import './resources/style.css';
import Footer from "./components/Footer";
import {BrowserRouter as Router} from "react-router-dom";
import AppRoutes from "./AppRoutes";
import {ToastContainer} from "react-toastify";
import {Provider} from "react-redux";
import reduxStore from './ReduxStore';

function App() {
    return (
        <Provider store={reduxStore}>
            <Router>
                <div className="appbody" style={{backgroundImage: `url(${background})`}}>
                    <header className={"centerMe"}>
                        <div className={"heightFullScreen maxWidthForReadibility"}>
                            <AppRoutes/>
                        </div>
                    </header>
                    <Footer></Footer>
                </div>
                <ToastContainer className={"mainMenuOptionsContainer"}
                    autoClose={5000}
                    closeButton={false}
                    closeOnClick
                    draggable
                    hideProgressBar={false}
                    limit={1}
                    newestOnTop={false}
                    pauseOnFocusLoss
                    pauseOnHover
                    position="bottom-center"
                />
            </Router>
        </Provider>
    );
}

export default App;