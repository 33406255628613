import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_ritesOvSummer.jpg";
import RitesOvSummerPlayer from "./RitesOvSummerPlayer";
import TrackContainer from "../../TrackContainer";
import BackOption from "../../../BackOption";

const RitesOvSummerDetails = () => {
    const { t } = useTranslation();
    let opusName = 'ritesOvSummer';

    function generateLyrics() {
        return (
            <p id={`${opusName}Lyrics`} className='centerText'>
                Untröstlichkeiten - in Sagen<br/>
                frühmenschlich strophischer Schau<br/>
                hört man von Geistern, die tragen<br/>
                den Mond, die Matte, den Tau,<br/>
                in Felsen legen sie Teiche,<br/>
                auf Schlünde Palmen und Wein,<br/>
                und hüllen in Zauberreiche<br/>
                die trauernden Völker ein.<br/> <br/>

                Untröstlichkeiten - beschwören<br/>
                mit Tanz und Maskenschar,<br/>
                Trommeln und Rindenröhren<br/>
                und die Fichte im Haar -<br/>
                beschwören die Stämme, die Rassen<br/>
                Dauer des süßen Scheins<br/>
                und erhoffen Erlassen<br/>
                der Gesetze des Seins.<br/> <br/>

                Doch da an einer Warte<br/>
                von Zucht und Ahnen alt<br/>
                lehnt eine flügelharte<br/>
                unsägliche Gestalt,<br/>
                ihr Blick, der Licht und Sterne<br/>
                und Buch und Zirkel hält,<br/>
                der sieht in eine Ferne,<br/>
                wo keine Träne fällt.<br/> <br/>
                Das ist die letzte Sphäre,<br/>
                ein Hoch- und Hafenland,<br/>
                da wächst die schwerste Ähre<br/>
                von jeder Glut gebrannt,<br/>
                sie wächst nicht, um zu leben,<br/>
                so singt der Ährenwind,<br/>
                sie wächst sich zu ergeben,<br/>
                wenn es der Genius sinnt.
            </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>{t(`${opusName}_description`)}
                    {t(`${opusName}_details01a`)}
                    <a href={`https://www.discogs.com/Various-Rites-Ov-Summer/release/4830945`}>
                        {t(`${opusName}_details01b`)}
                    </a>
                    {t(`${opusName}_details01c`)}
                    <a href={`../../../resources/04_Wo_keine_Traene_faellt.mp3.zip`} download>
                        {t(`${opusName}_details01d`)}
                    </a>
                    {t(`${opusName}_details01e`)}
                </p>
                <RitesOvSummerPlayer />
            </div>
            <div className={`marginPicture`}>
                <TrackContainer header={`Sommer`} credits={`Jännerwein`} lyrics={null} />
                <TrackContainer header={`Spelmannen`} credits={`Solblot`} lyrics={null} />
                <TrackContainer header={`Willekomen Sî Der Sumer`} credits={`Truthful Fields`} lyrics={null} />
                <TrackContainer header={`Wo keine Träne fällt`} credits={`moineau. Words: Gottfried Benn`} lyrics={generateLyrics()} />
                <TrackContainer header={`Reprise`} credits={`Vurgart`} lyrics={null} />
                <TrackContainer header={`Et In Arcadia Ego`} credits={`Bund Neuland`} lyrics={null} />
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default RitesOvSummerDetails;