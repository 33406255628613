import React, {useEffect, useState} from 'react';
import btnBack from '../../resources/img/icon_back.svg';
import btnForward from '../../resources/img/icon_forward.svg';

export interface ImagePageProps {
    gallery: string,
    numberOfPictures: number
}

const ImagePage = ( { gallery, numberOfPictures }: ImagePageProps ) => {
    const [apiImgIndex, setApiImgIndex] = useState<number>(1)

    useEffect(() => {
        nextImage(apiImgIndex)
    }, [])

    function nextImage(nextIndex: number) {
        if (nextIndex > numberOfPictures) {
            setApiImgIndex(1)
        } else if (nextIndex < 1) {
            setApiImgIndex(numberOfPictures)
        } else {
            setApiImgIndex(nextIndex)
        }
    }

    function previous() {
        nextImage(apiImgIndex - 1)
    }

    function next() {
        nextImage(apiImgIndex + 1)
    }

    function stringValue(value : number) {
        if (value < 10) {
            return '0' + value
        } else {
            return value.toString()
        }
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={"pictureSize"} src={'../../img/' + gallery + '/' + stringValue(apiImgIndex) + '.jpg'} alt={"gallery item"} />
            <div>
                <button className={"menuButton btnPositionGallery left"} onClick={previous}>
                    <img className={"iconSize"} src={btnBack} alt={"back"} />
                </button>
            </div>
            <div>
                <button className={"menuButton btnPositionGallery right"} style={{zIndex: '999'}} onClick={next}>
                    <img className={"iconSize"} src={btnForward} alt={"forward"} />
                </button>
            </div>
        </div>
    );
};

export default ImagePage;