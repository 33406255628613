import React from 'react';
import picGivingReasonDesire from '../../resources/img/pics/shop/givingReasonDesire_mainPic.jpg';
import picMoineauWestwind from '../../resources/img/pics/works/releases_cover_moineauWestwind.jpg';
import picGivingReasonDesireBox from '../../resources/img/pics/shop/givingReasonDesireBox_mainPic.jpg';
import picDeProfundisCantavi from '../../resources/img/pics/shop/deProfundisCantavi_mainPic.jpg';
import picVoxVeris from '../../resources/img/pics/shop/voxVeris_mainPic.jpg';
import picAbendlaeutenLp from '../../resources/img/pics/shop/abendlaeutenLp.jpg';
import picNachDerSehnsuchtLp from '../../resources/img/pics/shop/nachDerSehnsuchtLp.jpg';
import picEineHoffnungLp from '../../resources/img/pics/shop/eineHoffnungLp.jpg';
import picEineHoffnungCd from '../../resources/img/pics/shop/eineHoffnungCd.jpg';
import sabotageCd from '../../resources/img/pics/shop/sabotageCd.jpg';
import picPatchSparrow from '../../resources/img/pics/shop/patchSparrow_mainPic.jpg';
import picPatchSparrowPrintLarge from '../../resources/img/pics/shop/patchSparrowPrintLarge.jpg';
import picPatchSparrowPrintSmall from '../../resources/img/pics/shop/patchSparrowPrintSmall.jpg';
import picPatchMoineauLarge from '../../resources/img/pics/shop/patchMoineauLarge.jpg';
import picPatchMoineauSmall from '../../resources/img/pics/shop/patchMoineauSmall.jpg';
import picPatchBindRune from '../../resources/img/pics/shop/patchBindRunePrint.jpg';
import picPatchCross from '../../resources/img/pics/shop/patchCross.jpg';
import picShirtSeredSokoliv from '../../resources/img/pics/shop/shirtSeredSokoliv_01.jpg';
import picShirtXy from '../../resources/img/pics/shop/shirtXy_mainPic.jpg';
import picShirtXx from '../../resources/img/pics/shop/shirtXx_mainPic.jpg';
import picPinSparrow from '../../resources/img/pics/shop/pinSparrow_mainPic.jpg';
import picButtonSparrow from '../../resources/img/pics/shop/buttonSparrow_mainPic.jpg';
import picButtonMoineau from '../../resources/img/pics/shop/buttonMoineau_mainPic.jpg';
import picButtonBindRune from '../../resources/img/pics/shop/buttonBindRune_mainPic.jpg';
import picStickerGodPunish from '../../resources/img/pics/shop/stickerGodPunish_mainPic.jpg';
import picStickerMoineau from '../../resources/img/pics/shop/stickerMoineau_mainPic.jpg';
import picStickerOida from '../../resources/img/pics/shop/stickerOida_mainPic.jpg';
import ShopItem from "./ShopItem";

const ShopPage = () => {
    return (
        <div className={"centerMeHorizontally heightInherit marginTopLarge marginBottomLarge"}>
            <h1 className={'marginTop'}>Œ U V R E S</h1>
            <ShopItem name={'moineauWestwind'} coverPic={picMoineauWestwind}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'givingReasonDesire'} coverPic={picGivingReasonDesire}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'givingReasonDesireBox'} coverPic={picGivingReasonDesireBox}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'deProfundisCantavi'} coverPic={picDeProfundisCantavi}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'voxVeris'} coverPic={picVoxVeris}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'abendlaeutenLp'} coverPic={picAbendlaeutenLp}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'nachDerSehnsuchtLp'} coverPic={picNachDerSehnsuchtLp}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'eineHoffnungLp'} coverPic={picEineHoffnungLp}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'eineHoffnungCd'} coverPic={picEineHoffnungCd}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'sabotageCd'} coverPic={sabotageCd}/>

            <h1 className={'marginTop'}>T I S S U</h1>
            <ShopItem name={'patchSparrow'} coverPic={picPatchSparrow}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'shirtSeredSokoliv'} coverPic={picShirtSeredSokoliv}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'shirtXy'} coverPic={picShirtXy}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'shirtXx'} coverPic={picShirtXx}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'patchMoineauLarge'} coverPic={picPatchMoineauLarge}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'patchMoineauSmall'} coverPic={picPatchMoineauSmall}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'patchSparrowPrintLarge'} coverPic={picPatchSparrowPrintLarge}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'patchSparrowPrintSmall'} coverPic={picPatchSparrowPrintSmall}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'patchBindRunePrint'} coverPic={picPatchBindRune}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'patchCross'} coverPic={picPatchCross}/>

            <h1 className={'marginTop'}>D É C O R</h1>
            <ShopItem name={'pinSparrow'} coverPic={picPinSparrow}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'buttonSparrow'} coverPic={picButtonSparrow}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'buttonMoineau'} coverPic={picButtonMoineau}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'buttonBindRune'} coverPic={picButtonBindRune}/>
                <hr className={`fullWidth`}/>
            <ShopItem name={'stickerGodPunish'} coverPic={picStickerGodPunish}/>
            <hr className={`fullWidth`}/>
            <ShopItem name={'stickerMoineau'} coverPic={picStickerMoineau}/>
            <hr className={`fullWidth`}/>
            <ShopItem name={'stickerOida'} coverPic={picStickerOida}/>
        </div>
    );
};

export default ShopPage;