import React from 'react';
import '../../resources/style.css';
import { Link } from 'react-router-dom';
import MainMenuOption from "./MainMenuOption";

const MainMenuOptions = () => {
    return (
        <div>
            <Link to={{pathname: '/about'}}><MainMenuOption label={"Q U O I ?"}/></Link>
            <Link to={{pathname: '/works'}}><MainMenuOption label={"Œ U V R E"}/></Link>
            <Link to={{pathname: '/shop'}}><MainMenuOption label={"C H O S E S"}/></Link>
            <Link to={{pathname: '/concerts'}}><MainMenuOption label={"C O N C E R T S"}/></Link>
            <Link to={{pathname: '/contact'}}><MainMenuOption label={"C O N T A C T"}/></Link>
        </div>
    );
};

export default MainMenuOptions;