import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../ReduxStore';
import cartIconLight from '../../../resources/img/icon_cart_light.svg';
import {useTranslation} from 'react-i18next';
import {emptyCart, CartItem} from './CartSlice';
import mailIcon from '../../../resources/img/icon_mail.svg';
import AddToCart from './AddToCart';
import SubtractFromCart from './SubtractFromCart';
import axios from 'axios';

const ShoppingCart = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch()
    const cartContent = useAppSelector((state) => state.cart.cartContent)
    const [visibilityCartButton, setVisibilityCartButton] = useState<string>('invisible')
    const [visibilityCartContent, setVisibilityCartContent] = useState<string>('invisible')
    const [totalAmount, setTotalAmount] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [name, setName] = useState<string>('')
    const [street, setStreet] = useState<string>('')
    const [additionalAddressInfo, setAdditionalAddressInfo] = useState<string>('')
    const [town, setTown] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [recipientEmail, setRecipientEmail] = useState<string>('')
    const nameConst = 'name'
    const streetConst = 'street'
    const additionalAddressInfoConst = 'additionalAddressInfo'
    const townConst = 'town'
    const countryConst = 'country'
    const recipientEmailConst = 'recipientEmail'

    useEffect(() => {
        calculateTotals()
        if (cartContent.length === 0) {
            setVisibilityCartContent('invisible')
        } else {
            toggleCartButton()
        }
        initiateAddressData()
    }, [cartContent, totalAmount]);

    function initiateAddressData() {
        setName(t('oderMailName'))
        setStreet(t('oderMailStreet'))
        setAdditionalAddressInfo(t('oderMailAdditionalInfo'))
        setTown(t('oderMailTown'))
        setCountry(t('oderMailCountry'))
        setRecipientEmail(t('oderMailRecipient'))
    }

    function toggleCartButton() {
        if (cartContent.length > 0) {
            setVisibilityCartButton('')
        } else {
            setVisibilityCartButton('invisible')
        }
    }

    function calculateTotals() {
        var amount = 0
        var price = 0

        cartContent.map(item => {
            amount += item.amount
            price += item.price * item.amount
        })

        setTotalAmount(amount)
        setTotalPrice(price)
    }

    function toggleCart() {
        if (visibilityCartContent === 'invisible') {
            setVisibilityCartContent('')
            setVisibilityCartButton('invisible')
        } else {
            setVisibilityCartContent('invisible')
            setVisibilityCartButton('')
        }
    }

    function setSize(item: CartItem): string {
        if (item.category.toLowerCase().includes('shirt')) {
            return ', ' + item.size
        } else {
            return ''
        }
    }

    function isNullOrDefaultValue(specimen: string, defaultValue: string): boolean {
        if (!specimen || specimen === defaultValue) {
            return true
        } else {
            return false
        }
    }

    function noAddressOrRecipient(): boolean {
        return isNullOrDefaultValue(name, nameConst) ||
            isNullOrDefaultValue(street, streetConst) ||
            isNullOrDefaultValue(town, townConst) ||
            isNullOrDefaultValue(country, countryConst) ||
            isNullOrDefaultValue(recipientEmail, recipientEmailConst)
    }

    async function send() {
        if (noAddressOrRecipient()) {
            alert(t('orderNoRecipientData'))
            return
        }

        await axios.post(
            '/order',
            {
                recipient: 'talensletje@gmail.com',
                message: createEmailBody()
            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Accept: 'application/html',
                }
            }
        )
            .then(function (response) {
                console.log(response)
                clearCurrentCart()
                alert(t('orderSent'))
                window.location.reload()
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    function createEmailBody(): string {
        let emailBody: string = t('orderMailGreeting')
        cartContent.map(item => {
            emailBody += ` - ${item.shopName} (${item.category}${setSize(item)}, ${item.amount}× à ${item.price}€): ${item.price * item.amount}€\n`
        })
        emailBody += t('orderMailMiddle')
        emailBody += (name + "\n")
        emailBody += (street + "\n")

        if(isAdditionalInformationNotProvided()) {
            emailBody += (additionalAddressInfo + "\n")
        }

        emailBody += (town + "\n")
        emailBody += (country + "\n")
        emailBody += ("\nEmail: " + recipientEmail + "\n")
        emailBody += t('orderMailConclusion')
        return emailBody
    }

    function isAdditionalInformationNotProvided() {
        return !(additionalAddressInfo === 'besondere Angaben' || additionalAddressInfo === 'additional information')
    }

    function handleValueChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;

        switch (name) {
            case recipientEmailConst: setRecipientEmail(value)
                break;
            case nameConst: setName(value)
                break;
            case streetConst: setStreet(value)
                break;
            case additionalAddressInfoConst: setAdditionalAddressInfo(value)
                break;
            case townConst: setTown(value)
                break;
            case countryConst: setCountry(value)
                break;
            default:
                break;
        }
    }

    function clearCurrentCart() {
        clearForm();
        clearCart();
        setVisibilityCartButton('invisible');
        setVisibilityCartContent('invisible');
    }

    function clearForm() {
        setRecipientEmail('')
        setName('')
        setStreet('')
        setAdditionalAddressInfo('')
        setTown('')
        setCountry('')
    }

    function clearCart() {
        dispatch(emptyCart)
    }

    return (
        <div>
            <button onClick={toggleCart}
                    className={`bottomHalfLeft centerMe noStyle cursorPointer ${visibilityCartButton}`}>
                <span className={`lightText`} style={{zIndex: 99}}>{totalAmount}</span>
                <img className={`iconSize iconWidth`} src={cartIconLight} alt={`cart symbol`}
                     title={t(`shopShowCart`)}/>
            </button>
            <div id={`shoppingCart`} className={`${visibilityCartContent}`}>
                <button id={`shoppinCartCloseButton`} className={`noStyle marginSmall redTextOnHover topRightCorner`}
                        onClick={toggleCart}>×
                </button>
                <h3 className={`centerText marginBottomSmall`}>{t(`headerShoppingCart`)} ({totalAmount})</h3>
                <table className={`marginSmall`}>
                    <tbody>
                    {cartContent.map(item => {
                        return <tr>
                            <td className={'centerText marginBottomSmall marg'}>
                                <span className={'boldText redText'}>{item.shopName}</span>
                                <br/>
                                ({item.category}{setSize(item)}, à {item.price}€)
                                <br/>
                                <span className={`centerText`}>{item.amount}× (<SubtractFromCart item={item}
                                                                                                  icon={`-`}/>/<AddToCart
                                    item={item} icon={`+`}/>)
                                </span>
                            </td>
                            <td className={`paddingLeft`}>{item.amount * item.price}€</td>
                        </tr>
                    })}
                    </tbody>
                </table>
                <h4 className={`rightText paddingRight`}>{t(`totalPrice`)}: {totalPrice}€</h4>
                <form className={`fullWidth centerMe marginTop marginBottom`}>
                    <input className={'inputField marginBottom width75'} type={'text'} name={recipientEmailConst} placeholder={recipientEmail} onChange={handleValueChange}/>
                    <label className={`fullWidth centerMe marginBottomSmall`}>
                        {t('orderMailDeliveryAddress')}
                    </label>
                    <input className={'inputField marginBottomSmall width75'} type={'text'} name={nameConst} placeholder={name} onChange={handleValueChange}/>
                    <input className={'inputField marginBottomSmall width75'} type={'text'} name={streetConst} placeholder={street} onChange={handleValueChange}/>
                    <input className={'inputField marginBottomSmall width75'} type={'text'} name={additionalAddressInfoConst} placeholder={additionalAddressInfo} onChange={handleValueChange}/>
                    <input className={'inputField marginBottomSmall width75'} type={'text'} name={townConst} placeholder={town} onChange={handleValueChange}/>
                    <input className={'inputField marginBottomSmall width75'} type={'text'} name={countryConst} placeholder={country} onChange={handleValueChange}/>
                </form>
                <div className={`fullWidth centerMe`}>
                    <button className={`cursorPointer marginSides noStyle`} onClick={send}>
                        <img className={`iconSize iconWidth`} src={mailIcon} alt={`mail symbol`}
                             title={t(`sendOrder`)}/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCart;
