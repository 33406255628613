import React from 'react';
import iconBack from '../resources/img/icon_back.svg';

const BackOption = () => {
    return (
        <div className={"mainMenuOption bodyBtn bottomRight"}>
                <img className={`iconSize bottomRight foreground101`} src={`${iconBack}`} alt={`back`} />
        </div>
    );
};

export default BackOption;