import React from 'react';
import OpusHeaderNoLink from "../../OpusHeaderNoLink";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import coverPic from "../../../../resources/img/pics/works/releases_cover_detvijspasitel.jpg";
import BackOption from "../../../BackOption";
import TrackContainer from "../../TrackContainer";
import MainMenuOption from "../../../footer/MainMenuOption";
import DeTvijSpasitelPlayer from "./DeTvijSpasitelPlayer";
import EuropaPlayer from "./EuropaPlayer";

const DeTvijSpasitelDetails = () => {
    const { t } = useTranslation();
    let opusName = 'deTvijSpasitel';

    function generateLyrics() {
        return (
            <p>
                Де твій Спаситель? Де твій Господь?<br/>
                Де все, що він дав? На суд виходь<br/>
                Тут більш нікого, здалось мені.<br/>
                Лиш порожнеча, це все тобі.<br/><br/>

                Де твій Спаситель? Де твій Господь?<br/>
                Де все, що він дав? На суд виходь<br/>
                Покірний раб ти, і в очевидь<br/>
                Обіцяне ним царство - брехня та міф.<br/><br/>

                Де твій Спаситель? Де твій Господь?<br/>
                Де все, що він дав? На суд виходь<br/>
                Чи ти все віриш? Чи може ні?<br/>
                Все чого прагнув горить в огні.<br/><br/>

                Де твій Спаситель? Де твій Господь?<br/>
                Де все, що він дав? На суд виходь<br/>
                І лине плач твій, під дзвонів дзвін<br/>
                Почуй свій вирок, пекло твій дім.
            </p>
        );
    }

    return (
        <div className={"centerMe heightInherit"}>
            <img className={`coverPicSizeDetails marginPicture`} src={coverPic} alt={`${opusName}`} title={`${opusName}`} />
            <OpusHeaderNoLink name={`${opusName}`} />
            <div id={`${opusName}Details`}>
                <p>
                    {t(`${opusName}_description`)}
                    {t(`${opusName}_details01a`)}
                    <a href={`../../../resources/de_tvij_spasitel.zip`} download>
                        {t(`${opusName}_details01b`)}
                    </a>
                    {t(`${opusName}_details01c`)}
                </p>
            </div>

            <div className={`marginPicture`}>
                <TrackContainer header={`Де твій Спаситель?`} credits={`moineau. Translation: Андрій Вовк.`} lyrics={generateLyrics()} />
                <DeTvijSpasitelPlayer />
                <TrackContainer header={`Європа / Europa`} credits={`cut-up piece by moineau.`} lyrics={null} />
                <EuropaPlayer />
            </div>
            <Link to={{pathname: '/works'}}><BackOption /></Link>
        </div>
    );
};

export default DeTvijSpasitelDetails;